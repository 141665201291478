import React from "react";
// import ApplyNowMainComp from '../components/ApplyNow/ApplyNowMainComp';
import Layout from "../../../components/layout";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .toast-bar {
    color: white;
    background-color: #4dd2ff;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .toast-bar-progress {
    background: #00bfff;
  }
`;

export default function App() {
  useEffect(() => {
    setTimeout(() => {
    window.location.href = "/apply-now";
    },3000 );
  }, []);

  return (
    <Layout>
      <StyledWrapper className="pt-lg-5 pt-4">
        <ToastContainer />
        <div style={{ color:"#464fd0"}} className="card p-4 text-center w-50 m-auto border-0 shadow-lg mt-lg-5 mt-4">
          <h2>
            Thank you for registering for support from upReach. <br /> We will
            be in contact shortly.
          </h2>
        </div>
      </StyledWrapper>
    </Layout>
  );
}
